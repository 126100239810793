<script>
export default {}
</script>

<template>
  <v-row :class="$style.topbar">
    <v-col>
      <div :class="$style.tabActive">{{ $t('ALL_COMPANIES') }}</div>
    </v-col>
    <v-col :class="$style.searchEnterprise">
      <!-- <v-text-field
        v-model="search"
        dark
        single-line
        hide-details
        clearable
        flat
        dense
        full-width
        prepend-icon="search"
        label="Search for company name or VAT number"
      ></v-text-field>-->
    </v-col>
  </v-row>
</template>

<style lang="scss" module>
@import '@design';
.tab {
  display: inline-block;
  padding: 0 35px 0 28px;
  font-size: 16px;
  font-weight: bold;
  line-height: 50px;
  color: white;
  &:hover {
    background: rgba(white, 0.5);
  }
}

.tabActive {
  position: relative;
  display: inline-block;
  padding: 3px 34px 0 28px;
  margin-top: 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 50px;
  color: $dark;
  background: white;
  border-radius: 10px 10px 0 0;
}

.topbar {
  align-items: center;
  justify-content: space-between;
  margin: 0 40px 0 40px;
  overflow: hidden;
  background: lighten($dark, 3%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.searchEnterprise {
  width: 435px;
  margin: -16px 0;
}
</style>
