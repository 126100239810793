<script>
import Loader from '@components/custom-loader/custom-loader'

import {
  enterpriseListComputed,
  enterpriseListMethods,
} from '@state/helpers/enterpriseList'

export default {
  components: {
    Loader,
  },
  computed: {
    ...enterpriseListComputed,
  },
  mounted() {
    this.fetchEnterprises()
  },
  methods: {
    ...enterpriseListMethods,
    setCurrentPage(page) {
      this.fetchEnterprises({ page })
    },
    onSelectEnterprise(enterprise) {
      this.$store.dispatch('enterprise/getSelectedEnterprise', {
        enterpriseId: enterprise.id,
      })
    },
  },
}
</script>

<template>
  <div :class="$style.container">
    <Loader v-if="isLoadingEnterprises" />
    <v-simple-table
      v-else
      class="enterprises compactHead"
      :class="$style.table"
    >
      <template v-slot>
        <thead>
          <th>{{ $t('COMPANY_NAME') }}</th>
          <th>{{ $t('COMPANY_CONTACT_PERSON') }}</th>
          <th>{{ $t('LABEL_PHONE_NUMBER') }}</th>
          <th>{{ $t('LABEL_VAT_NUMBER') }}</th>
          <th>{{ $t('ENTERPRISE_LIST.ORIGIN') }}</th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="enterprise in enterprises" :key="enterprise.id">
            <td :class="$style.td">
              <span :class="$style.bigtext">{{ enterprise.name }}</span>
            </td>
            <td>{{ enterprise.contactPerson.fullName }}</td>
            <td>{{ enterprise.fullPhoneNumber }}</td>
            <td>{{ enterprise.vatNumber }}</td>
            <td>{{ enterprise.originatingSystem }}</td>
            <td class="cta">
              <v-btn
                class="naked-btn primary bold"
                text
                @click="onSelectEnterprise(enterprise)"
                >{{ $t('SELECT_ENTERPRISE') }}</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-pagination
      v-if="pagination.totalResults > pagination.pageSize"
      :value="pagination.page"
      class="custom"
      :next-icon="$t('NEXT')"
      :prev-icon="$t('PREVIOUS')"
      :length="Math.ceil(pagination.totalResults / pagination.pageSize)"
      @input="setCurrentPage"
    ></v-pagination>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.container {
  width: 100%;
}

.table {
  width: 100%;
  margin: 0 0 10px 0;
  tr {
    height: 74px;
    font-weight: bold;
    &:focus,
    &:hover {
      background: white !important;
    }
    td {
      font-size: 16px;
      font-weight: 400;
      color: #8c949e;
    }
  }
  tr td:first-child {
    padding-left: 35px;
  }
}

.bigtext {
  font-size: 16px;
  font-weight: 600;
  color: #1d1d1d;
  letter-spacing: 0;
}
.blackText {
  margin-right: 10px;
  font-weight: 600;
  color: $dark;
}

.searchEnterprise {
  width: 435px;
  margin: -16px 0;
}
</style>
