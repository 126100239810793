<script>
import Layout from '@layouts/main/main'
export default {
  components: { Layout },
}
</script>

<template>
  <Layout>
    <slot />
  </Layout>
</template>
