import { mapState, mapActions, mapGetters } from 'vuex'

export const enterpriseListComputed = {
  ...mapGetters('enterpriseList', {
    enterprises: 'getEnterprises',
  }),
  ...mapState('enterpriseList', {
    isLoadingEnterprises: (state) => state.isLoadingEnterprises,
    pagination: (state) => state.pagination,
  }),
}

export const enterpriseListMethods = mapActions('enterpriseList', [
  'fetchEnterprises',
])
