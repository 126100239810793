<script>
import { enterpriseListComputed } from '@state/helpers/enterpriseList'
import Loader from '@components/custom-loader/custom-loader'
import i18n from '@src/i18n'
import Layout from '@layouts/enterprises/enterprises'
import EnterpriseSearch from '@components/enterprise-search/enterprise-search'
import EnterpriseList from '@components/enterprise-list/enterprise-list'
import { pick } from 'lodash'

export default {
  page: {
    title: i18n.t('ENTERPRISES'),
    meta: [{ name: 'description', content: 'Enterprises' }],
  },
  components: {
    Layout,
    EnterpriseList,
    EnterpriseSearch,
    Loader,
  },
  computed: {
    ...pick(enterpriseListComputed, ['isLoadingEnterprises']),
  },
}
</script>

<template>
  <Layout>
    <Loader v-if="isLoadingEnterprises" />
    <div :class="$style.description">
      <h1 :class="$style.title">{{ $t('ENTERPRISES') }}</h1>
    </div>
    <v-row :class="$style.test">
      <EnterpriseSearch />
    </v-row>
    <v-row :class="$style.enterprises">
      <EnterpriseList />
    </v-row>
  </Layout>
</template>

<style lang="scss" module>
@import '@design';

.description {
  padding: 20px 40px;
  background: $dark;
}

.title {
  color: #fff;
}

.table {
  width: 100%;

  tr {
    height: 74px;
    font-weight: bold;
    &:hover {
      background: white !important;
    }
  }
  tr td:first-child {
    padding-left: 35px;
  }
}

.test {
  background: $dark;
}

.bigtext {
  font-size: 16px;
  line-height: 23px;
  color: $dark;
}

.smallertext {
  margin-right: 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #8c949e;
}

.enterprises {
  padding: 0 40px 50px;
}
</style>
